import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from './contexts/ThemeContexts';
// import './App.css';

import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { RoutesApp } from './routes';
import { AuthProvider } from './contexts/AuthContexts';

import i18n from './utils/i18n';
import { Maintenance } from './pages/Maintenance/Maintenance';
import { GlobalStyle } from './GlobalStyles';
import { LanguageProvider } from './contexts/LanguageContext';
import { useEffect, useState } from 'react';

const status = process.env.REACT_APP_STATUS;

function App() {
  const [mainUrl, setMainUrl] = useState(false);

  useEffect(() => {
    verifyUrl();
  }, []);

  function verifyUrl() {
    const currentUrl = window.location.href;

    // BLOCK 02
    if (currentUrl.includes('searchvu-web.vercel.app')) {
      setMainUrl(true);
    }
    // if (currentUrl.includes('explorevu.com')) {
    //   setMainUrl(true);
    // }
  }

  // if (mainUrl && status === 'maintenance') {
  //   return (
  //     <BrowserRouter>
  //       <I18nextProvider i18n={i18n}>
  //         <ThemeProvider>
  //           <LanguageProvider>
  //             <GlobalStyle />
  //             <Maintenance />
  //           </LanguageProvider>
  //         </ThemeProvider>
  //       </I18nextProvider>
  //     </BrowserRouter>
  //   );
  // }

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider>
          <LanguageProvider>
            <GlobalStyle />
            <Maintenance />
          </LanguageProvider>
        </ThemeProvider>
      </I18nextProvider>
    </BrowserRouter>
  );

  // return (
  //   <BrowserRouter>
  //     <I18nextProvider i18n={i18n}>
  //       <ThemeProvider>
  //         <LanguageProvider>
  //           <GlobalStyle />
  //           <AuthProvider>
  //             <Toaster />
  //             <div className="contentArea">
  //               <div className="contentTop">
  //                 <Header />
  //                 <RoutesApp />
  //               </div>
  //               <Footer />
  //             </div>
  //           </AuthProvider>
  //         </LanguageProvider>
  //       </ThemeProvider>
  //     </I18nextProvider>
  //   </BrowserRouter>
  // );
}

export default App;
