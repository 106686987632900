import { useTranslation } from 'react-i18next';
import { Container } from './MaintenanceStyles';

import LOGO from '../../assets/logos/logo_v1_ORIGIN.png';
import BRAZIL from '../../assets/flags/brazil.png';
import ENGLISH from '../../assets/flags/english.png';
import SPANISH from '../../assets/flags/spanish.png';
import { useContext } from 'react';
import { FiMoon, FiSun } from 'react-icons/fi';
import { ThemeContext } from '../../contexts/ThemeContexts';
import { LanguageContext } from '../../contexts/LanguageContext';

type FlagInterface = {
  language: 'en' | 'es' | 'pt';
  img: string;
};

const FLAGS_ENABLED: FlagInterface[] = [
  {
    language: 'en',
    img: ENGLISH,
  },
  {
    language: 'es',
    img: SPANISH,
  },
  {
    language: 'pt',
    img: BRAZIL,
  },
];

export function Maintenance() {
  const { t } = useTranslation();
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const { language, changeLanguage } = useContext(LanguageContext);

  if (!language || !changeLanguage) {
    throw new Error(
      'useContext(LanguageContext) must be used within a LanguageProvider'
    );
  }

  return (
    <Container>
      <img id="logo" src={LOGO} alt="ExploreVu" />
      <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
        {t('alert_banner_message_title')}
      </p>
      <br />
      <p>
        {t('alert_banner_message_new')}
        <br />
        <br />
        <b>E-mail: </b> contact@explorevu.com <b>|</b> <b>Instagram: </b>{' '}
        @explore.vu
        <br />
        <br />
      </p>
      <ul className="flags">
        {FLAGS_ENABLED.map((flag, index) => (
          <img
            key={index}
            onClick={() => changeLanguage(flag.language as 'en' | 'es' | 'pt')}
            src={flag.img}
            alt={flag.language}
            style={{
              opacity: language === flag.language ? 1 : 0.7,
              borderColor: language === flag.language ? '#f49c14' : '#ededed',
            }}
          />
        ))}
        <span className="btnTheme" onClick={toggleTheme}>
          {isDarkMode ? <FiSun /> : <FiMoon color="#999" />}
        </span>
      </ul>
    </Container>
  );
}
